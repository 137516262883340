@import '../../../style.mixins.scss';

.manage-fact {
    .dialog-heading {
        color: rgb(99, 98, 98);
    }

    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:last-child  {
        width: 10%;
    }
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:nth-child(4) {
        width: 9%;
    }


}


.ManageFactForm {
            .feild-label {
                font-size:$l2-font-size ;
                height: auto;
                font-weight:500;
            }  
            
            .p-multiselect {
                display: flex;
                max-width: 100%;
            
                .p-multiselect-trigger{
                   display: block;
                   width: auto;
                }
            }
}


