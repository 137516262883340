.manage-user-group {
    .dialog-heading {
        color: rgb(99, 98, 98);
    }

    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:last-child  {
        width: 10%;
    }
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:first-child {
        width: 20%;
    }

    .p-datatable .p-datatable-tbody > tr > td{
      word-break: break-all;  
    }
  
}


.ManageUserGroupForm {
            .feild-label {
                font-size: 0.9rem;
                height: auto;
                font-weight:500;
            } 
}


