@import '../../../style.mixins.scss';

.request-container {
    text-align: left;
    .kpi-title,
    .report-title {
        font-weight: 600;
        color: #3c4852;
    }
   

    .report-title {
        max-width: 70%;
    }

    .tile {
        .tile-box {
            padding: 1rem;
            border-radius: 8px;
        }

        .desc {
            height: 80px;
            max-height: 80px;
            overflow: hidden;
            margin-top: 10px;
            margin-bottom: 10px;
            color: #828589;
        }

        .icon {
            height: 50px;
            min-width: 50px;
            font-size: 24px;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        }

        &:nth-child(1) {
            .icon {
                background-color: rgba(#84bc3f, 0.5);
                color: #84bc3f;
            }
        }
        &.lime {
            .icon {
                background-color: rgba(#84bc3f, 0.5) !important;
                color: #84bc3f !important;
            }
        }

        &:nth-child(2) {
            .icon {
                background-color: rgba(#2c7ab6, 0.5);
                color: #2c7ab6;
            }
        }
        &.sky_blue {
            .icon {
                background-color: rgba(#2c7ab6, 0.5) !important;
                color: #2c7ab6 !important;
            }
        }

        &:nth-child(3) {
            .icon {
                background-color: rgba(#f2693e, 0.5);
                color: #f2693e;
            }
        }
        &.orange {
            .icon {
                background-color: rgba(#f2693e, 0.5) !important;
                color: #f2693e !important;
            }
        }

        &:nth-child(4) {
            .icon {
                background-color: rgba(#5783c8, 0.5);
                color: #5783c8;
            }
        }
        &.light_blue {
            .icon {
                background-color: rgba(#5783c8, 0.5) !important;
                color: #5783c8 !important;
            }
        }

        &:nth-child(5) {
            .icon {
                background-color: rgba(#b8a2fe, 0.5);
                color: #b8a2fe;
            }
        }
        &.lilac {
            .icon {
                background-color: rgba(#b8a2fe, 0.5) !important;
                color: #b8a2fe !important;
            }
        }

        &:nth-child(6) {
            .icon {
                background-color: rgba(#ffb244, 0.5);
                color: #ffb244;
            }
        }
        &.marigold {
            .icon {
                background-color: rgba(#ffb244, 0.5) !important;
                color: #ffb244 !important;
            }
        }

        .view-link {
                span {
                    color:#3c4852 ; 
                    font-weight: 600;
                    
                }
                .mdi {
                    vertical-align: middle;
                    font-size: 14px;
                    line-height: 0px;
                }
            
        }
    }
}

.text-green {
    color: #4ea626;
}
