.manage-notification {
    .p-datatable .p-datatable-tbody > tr > td {
        word-break: break-word;
    }
    
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:last-child  {
        width: 45%;
    }
   .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:nth-child(3) {
    width: 10%;
  }
    
}
.ManageNotification {
    .p-dropdown {
        max-width: 100% ;
    }
    .p-dropdown{
        display: flex;
        .p-dropdown-trigger{
            display: block;
            width: auto;
         }
    }

}
    


    