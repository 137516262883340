.manage-solution-mapping {

    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:nth-child(2) {
        width: 55%;
    }
    .p-datatable .p-datatable-tbody > tr > td:nth-child(-n+2){
     word-break:break-all;
  
    }

    .form-container {
        .action-btn {
            width: 20%;
        }
    }
    .p-dropdown {
        display: flex;
        max-width: 100%;
        .p-dropdown-trigger{
            display: block;
            width: auto;
         }
    }

    .p-multiselect {
        display: flex;
        // max-width: 100%;
    
        .p-multiselect-trigger{
           display: block;
           width: auto;
        }
    }

    .custom-background-multiselect {
        .p-multiselect {
            background-color: white;
            border: 0.2px solid grey;
            display: flex;
            
        }
    }

    .feild-label {
        
        height: auto;
        
    }  
     
}    