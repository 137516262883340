.progress-container {
    z-index: 12000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loader-img {
        height: 90px;
        width: 90px;
    }
    .loader-div {
        width: 200px;
        height: 200px;
        position: absolute;
        border: 2px solid white;
        border-top: 2px solid black;
        border-right: 2px solid black;
        border-radius: 50%;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        animation: animate-loader 1s linear infinite;
    }

    .mdi {
        font-size: 5.5rem;
    }
}

@keyframes animate-loader {
    100% {
        transform: rotate(1turn);
    }
}
