@import "../../../style.mixins.scss";

.max-height-38 {
    max-height: 38px;
    height: 38px;
}

.invalid {
    border: solid 1px !important;
    border-color: #dc3545 !important;
}

.p-inputtext {
    padding: 0;
    line-height: 26px;
    font-size: $l2-font-size;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-family: "Open Sans";
}

.p-dropdown-label {
    width: 100%;
}

.p-dropdown-filter {
    box-shadow: none !important;
}

.form-control {
    // border: none;
    background-color: rgba(0, 0, 0, 0.03);
    color: #495057;

    &.p-calendar {
        display: flex;
    }

    .p-button {
        background: no-repeat;
        background-color: transparent;
        border: none;
        width: 2rem !important;
        font-size: 24px;
        color: #7e7e7e;

        &:active {
            background-color: transparent;
            border: none;
            color: #7e7e7e;
        }
        &:hover {
            background-color: transparent;
            border: none;
            color: #7e7e7e;
        }

        &:focus {
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    }
}

.form-label {
    color: #35383a;
    margin-bottom: 0 !important;
}

.p-dropdown-filter:focus {
    border-color: #5b6770 !important;
}
.input-text {
    font-size: $l2-font-size;
    font-family: "Open Sans";
    height: 38px;
}

textarea {
    &.input-text {
        height: auto;
    }
}

.input-text:focus {
    border: 1px solid #5b6770 !important;
}

.input-text::placeholder {
    color: #a1a2a3;
    font-size: smaller;
}

.p-placeholder {
    font-size: smaller;
    color: #a1a2a3 !important;
}

.p-multiselect {
    &:hover {
        border-color: #ced4da !important;
    }

    .p-multiselect-label {
        padding: 0;
    }
}

.p-multiselect-close {
    display: none;
}

.p-multiselect-filter {
    border-color: #5b6770 !important;
}

.p-calendar .p-inputtext {
    width: 100%;
    border: none;
    background: transparent;
    font-family: "Open Sans";

    &::placeholder {
        color: #a1a2a3;
        font-size: smaller;
    }
}
.p-calendar .p-inputtext:focus {
    box-shadow: none;
}

.p-multiselect .p-multiselect-trigger {
    display: none;
}

.p-dropdown .p-dropdown-trigger {
    display: none;
}

.textarea {
    padding: 6px 12px;
    resize: horizontal;
}
.p-dropdown-panel,
.p-multiselect-panel {
    max-width: 400px;
    font-size: $l2-font-size;
    font-family: "Open Sans";
}
.p-multiselect-item,
.p-multiselect-empty-message {
    font-size: $l2-font-size;
    font-family: "Open Sans";
    text-wrap: balance;
}

.p-dropdown-item {
    white-space: pre-wrap;
}

.p-multiselect-footer {
    text-align: end;
}

.p-datepicker-month {
    margin-right: 2rem !important;
}
.p-column-filter-buttonbar {
    font-family: "Open Sans";
}

.p-datepicker-month,
.p-datepicker-year {
    font-weight: bold;
    color: #495057;
    border: none;
    padding: 0.2rem 0.4rem;
    font-size: $l2-font-size;
    font-family: "Open Sans";
}

.p-datepicker-month:focus,
.p-datepicker-year:focus {
    box-shadow: none !important;
}

.p-datepicker .p-datepicker-header {
    font-size: 14px;
}
.p-datepicker table {
    font-size: 14px;
}
.p-datepicker table td {
    padding: 0.1rem;
}

.p-datepicker {
    min-width: 320px !important;
    font-family: "Open Sans";
}

.panel-width-850 {
    .p-multiselect-items {
        width: 100%;
    }
    .p-dropdown-items {
        width: 100%;
    }
}

.custom-panel-class {
    .p-multiselect-items {
        width: auto;
    }
    .p-dropdown-items {
        width: auto;
    }
    .p-dropdown-header .p-dropdown-filter {
        margin-right: -1.53rem;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: rgba(0, 0, 0, 0.03);
}

.ql-blank::before {
    color: #a1a2a3 !important;
    // font-size: smaller;
}
.p-editor-toolbar {
    display: none;
}

.ql-picker.ql-header .ql-picker-label::before {
    margin-right: 0.75rem;
}

.p-datepicker {
    .p-yearpicker {
        .p-disabled {
            cursor: pointer !important;
            opacity: 1;
            pointer-events: auto;
            user-select: auto;
            &:hover {
                background-color: #e9ecef;
            }
        }
    }
}

.p-dropdown .p-dropdown-clear-icon {
    right: 2.5rem !important;
}

.p-button {
}

.radio-button-filter {
    .label-input-container {
        display: flex;
        align-items: center;
        .form-label {
            margin-right: 1rem;
        }
    }

    .mb-3 {
        margin-bottom: 0 !important;
    }
}

.margin-top-minus-2 {
    margin-top: -0.5rem;
}

input[type="radio"] {
    accent-color: #293d56;
    height: 14px;
    width: 14px;
}

input[type="checkbox"]:checked {
    background-color: #293d56;
    border-color: #293d56;
}

input[type="checkbox"] {
    height: 20px;
    width: 20px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #293d56;
    background-color: #eef1f5;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #293d56;
}

.p-dropdown:not(.p-disabled).p-focus {
    border-color: #293d56;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #293d56;
    background-color: #eef1f5;

    &:hover {
        color: #293d56;
        background-color: #eef1f5;
    }

    &:focus {
        color: #293d56;
        background-color: #eef1f5;
    }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #293d56;
    background-color: #293d56;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #293d56;
    &:hover {
        border-color: #293d56;
        background-color: #293d56 !important;
    }
}

.p-checkbox .p-checkbox-box {
    border-radius: 3px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.error-icon {
    display: flex;
    padding: 3px;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #f4e0e0;
    border-radius: 50%;
}
