.process-flow-container {
    padding-top: 1.5rem;
    padding-bottom: 5rem;
    // background-color: #eff6ff;
    
    .process-wrapper {
        width: 80%;
        border-radius: 20px;
    }
}

@keyframes progress-animation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
