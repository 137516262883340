@import "../../../../style.mixins.scss";
.singleselect_container {
    // position: relative;
    ul {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
    }

    .value-container {
        height: 38px;
        padding: 0;
        line-height: 26px;
        font-size: 14px;
        padding-left: 1rem;
        padding-right: 0.75rem;
        font-family: "Open Sans";
        max-width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        max-height: 38px;
        text-overflow: ellipsis;
        text-wrap: nowrap;

        .down-icon {
            // font-size: 24px;
            // font-weight: 100;
        }
    }

    .placeholder-text,
    .value {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .placeholder-text {
        font-size: 13.3333px;
        color: #a1a2a3 !important;
    }
    .suggestion-box {
        position: absolute;
        // top: 38px;
        background-color: white;
        max-height: 40vh;
        overflow: auto;
        padding: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2rem;
        li {
            list-style: none;
            text-align: left;
            color: #3c4852;
            font-weight: bold;
            label {
                cursor: pointer;
            }
        }

        li:nth-of-type(even) {
            background-color: #f2f2f2;
        }
        li {
            .close-icon {
                display: none;
            }
        }

        li.selected {
            background-color: #293d56;
            color: #fff;
            .close-icon {
                display: inline;
            }
        }

        li:hover {
            background-color: #c6defc;
            color: #495057;
        }
        li.disabled {
            color: #3c4852ba;
            background-color: transparent;
        }
    }

    .search-container {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 100;
    }
}
