.field {
    .form-check-input {
        min-width: 16px;
    }

    .correct {
        background-color: #c2e3d3 !important;
        color: #198754;
        .mdi {
            font-size: 24px;
            line-height: 24px;
        }
    }

    .incorrect {
        background-color: #ebc4c8 !important;
        color: #dc3545;
        .mdi {
            font-size: 24px;
            line-height: 24px;
        }
    }
}
