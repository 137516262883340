.biLoader-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
    background: rgba(#000, 0.95)
}

.biLoader {
    height: 30%;
    width: 40%;
    @media screen and (min-width: 1300px) {
        width: 35%;
    }
    border-left: 5px solid #82cf7d;
    border-bottom: 5px solid #82cf7d;
    div {
        width: 11%;
        height: 10%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        // background-color: #2fb087 ;
        animation: bi-loading 1.8s linear infinite;
        margin: 0px auto;
        &:nth-child(1) {
            background-color: rgba(#f2693e, 0.8);
        }

        &:nth-child(2) {
            background-color: rgba(#2c7ab6, 0.8);
        }

        &:nth-child(3) {
            background-color: rgba(#b8a2fe, 0.8);
        }

        &:nth-child(4) {
            background-color: rgba(#ffb244, 0.8);
        }

        &:nth-child(5) {
            background-color: rgba(#ff8300, 0.8);
        }

        &:nth-child(6) {
            background-color: rgba(#5783c8, 0.8);
        }
        &:nth-child(7) {
            background-color: rgba(#e15b64, 0.8);
        }
        &:nth-child(8) {
            background-color: rgba(#84bc3f, 0.8);
        }
    }
}

@keyframes bi-loading {
    0% {
        height: 10%;
    }
    50% {
        height: 92%;
    }
    100% {
        height: 10%;
    }
}
