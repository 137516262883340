$side-nav-width: 50px;
$background-color: #3d4957;
$color: #f8f9fb;
$l0-font-size:10px;
$l1-font-size:12px;
$l2-font-size:14px;
$l3-font-size:16px;
$big-screen-l0-font-size:14px;
$big-screen-l1-font-size:16px;
$big-screen-l2-font-size:18px;
$big-screen-l3-font-size:20px;
