.notification-container {
    background-color: #eff6ff;
    height: 100%;
    .content-wrapper {
        // border-ra: 20px;
        width: 60%;
        background-color: white;
        .content-container:last-child {
            border: none !important;
            padding-bottom: 0 !important;
        }

        .content-container:first-child {
            padding-top: 0 !important;
        }
        .notification-desc {
            // color: #818f9e;
            p {
                margin-bottom: 0;
            }
        }
    }

    .unread {
        border-left: solid 5px #0d6efd;
    }

    .date-color {
        color: #818f9e;
    }
}
