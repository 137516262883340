.manage-user {
    .dialog-heading {
        color: rgb(99, 98, 98);
    }

    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:last-child  {
        width: 20%;
    }
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:nth-child(-n+2) {
        width: 20%;
    }

    .p-datatable .p-datatable-tbody > tr > td:nth-child(-n+2){
       
        overflow: hidden; 
        white-space:nowrap;
        text-overflow:ellipsis; 
        
    }
  

}


.ManageUserForm {
            
            .p-multiselect {
                display: flex;
                max-width: 100%;
            
                .p-multiselect-trigger{
                   display: block;
                   width: auto;
                }
            }
}


