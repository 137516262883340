@import '../../../style.mixins.scss';

.group-email {
    .dialog-heading {
        color: rgb(99, 98, 98);
    }
    
}

.group-email-form {

    .max-width-100 {
        max-width:100%;
        width: 100%;
    }
    .upper-div{
        .field-container {
            max-width:calc(100% - 58px) ;
            width: 100%;
            .groups-label{
                cursor: default;
            }
            .p-multiselect {
                .p-multiselect-label{
                    text-align: start;
                }
                display: flex;
                color: #4338CA;
                max-width:100%;
                .p-multiselect-trigger{
                   display: block;
                   width: auto;
                }
            }
        }

    }
    .send-email-icon {
        line-height: 0px;
    }
    .ql-editor {
        // height: 200px !important;
        font-size: $l2-font-size;
    }
    .ql-editor.ql-blank::before {
        font-style: normal;
    }
}




