.sitemap-container {
    padding: 30px;
    display: flex;
    // flex-direction: column-reverse;
    .node {
        border: 1px solid #78d64b;
        padding: 0.25rem;
        height: 32px;
        background: #78d64b1f;
        border-radius: 3px;
        cursor: pointer;
        white-space: nowrap;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            transform: scale(1.1);
            background-color: #78d64b;
            color: #fff;
            transition: 0.2s linear all;
        }
    }
    .horizontal-line {
        border-top: 1px solid #78d64b;
        box-sizing: border-box;
        height: 1px;
        width: 60px;
        margin-top: 1rem;
    }

    .vertical-line {
        height: calc(100% - 32px);
        border-left: 1px solid #78d64b;
        box-sizing: border-box;
        width: 1px;
        margin-top: 1rem;
    }
}
