.confirmation-container {
    .content {
        width: 40%;
        .w-15 {
            width: 15%;
        }
        span {
            font-size: 5rem;
        }
       
    }
}
