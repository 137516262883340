.caraousel-container {
    // background-color: #eff6ff;
    // padding: 10px;
    // box-sizing: content-box;
    width: 100%;
    max-width: 100%;
    .outer-container {
        overflow: hidden;
        padding: 0px;
        box-sizing: border-box;
        width: 100%;

        .inner-container {
            overflow-x: auto;
            overflow-y: hidden;
            display: flex;
            scroll-behavior: smooth;
            cursor: grabbing;
            .child {
                // display: none;
                min-width: 100%;
                // max-height: 100%;
                height: fit-content;
                overflow: hidden;
            }
        }
    }

    .nav-icon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fafafa;
    }
}
