.no-access {
    
    .door-icon {
        color: #005366;
        font-size: 120px;
    }

    .title {
        color: #005366;
    }

    .desc {
        font-weight: 500s;
    }

    .btn-back {
        background: #005366;
        color: #fff;
        border-radius: 10px;
        padding: 7px 28px;
    }

    &.width-60 {
        width: 60%;
        @media screen and (min-width: 1300px) {
            width: 40%;
        }
    }
}
