.manage-content-package-form {
   
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:nth-child(2) {
        width: 24% ;
    }
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:nth-child(3) {
        width: 24% ;
    }
    .p-datatable .p-datatable-tbody > tr > td{
     word-break:break-all;
    }

    .p-dropdown {
        display: flex;
        max-width: 100%;
        .p-dropdown-trigger{
            display: block;
            width: auto;
         }
    }
    .custom-background-dropdown {
        .p-dropdown {
            background-color: white;
            border: 0.2px solid grey;
            display: flex;
            max-width: 100%;    
        }
    }
     
}    