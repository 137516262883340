@import '../../../style.mixins.scss';

.bi-sidenav {
    background-color: #293d56;
    width: 18%;
    @media screen and (min-width: 1300px) { 
        width: 250px;
    }
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    max-height: 100vh;
    font-size: $l2-font-size;
    @media screen and (min-width: 1300px) {
        font-size: $big-screen-l1-font-size;
    }
    .sidenav-header {
        background-color: #19232e;
        .user-container {
            width: 3.15vw;
            height: 3.15vw;
            background-color: #eef1f5;
        }
    }
    .sidenav-body {
        max-height: 80vh;
        overflow-y: auto;
        .application {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            list-style: none;
            border-bottom: 0.5px solid white;
            color: white;
        }
    }

    .sidenav-body::-webkit-scrollbar {
        display: none;
    }

    .sidenav-bottom {
        // background-color: #19232e;
        .application {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            list-style: none;
            color: white;
            // border-bottom: 0.1px solid #ccc;
            // border-top: 0.1px solid #ccc;
        }
    }
    .cursor-pointer {
        cursor: pointer;
    }

    .logo-img {
        width: 72px;
    }

    .brand-logo {
        .mdi-menu {
            color: #222539;
        }
    }
}

.bi-sidenav.close {
    width: 0;
}
