.bi-navbar {
    font-weight: 600;
    background-color: #293d56;

    .navbar-item-container {
        width: 100%;
        height: 40px;
    }

    .logo-img {
        width: 72px;
    }

    &.z-index-1001 {
        z-index: 1001 !important;
    }

    .region-dropdown {
        background-color: transparent;
        width: 50px;
        font-weight: 600;
        border-radius: 0;

        .p-inputtext {
            color: #162252;
        }

        .p-dropdown-label {
            padding: 0.25rem 0.75rem;
        }

        .p-dropdown-trigger {
            display: none;
        }
    }

    .mail-icon {
        display: inline-block;
        vertical-align: middle;
    }

    .icon-chev {
        font-size: 30px;
    }

    .user-container {
        width: 36px;
        height: 36px;
        background-color: #eef1f5;
        color: #293d56;
    }
}

.region-panel {
    top: 50px !important;

    .p-dropdown-items-wrapper {
        background-color: #162252;
        max-height: 400px !important;
    }

    .p-dropdown-item {
        padding: 0.25rem 0 !important;
        text-align: center;
        color: white !important;
    }

    .p-dropdown-items .p-dropdown-item.p-highlight {
        color: #222539 !important;
        background-color: white;
    }

    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: #222539 !important;
        background-color: white;
    }
}
