@import "~bootstrap/scss/bootstrap";
@import "~@mdi/font/scss/materialdesignicons.scss";
@import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
@import "primereact/resources/primereact.min.css"; //core css
@import "primeicons/primeicons.css";
@import "@fontsource/montserrat";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");

body {
  margin: 0;
  // font-family: Montserrat regular -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  //   "Droid Sans", "Helvetica Neue", sans-serif;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  h1 {
    font-size: 32px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 18.72px !important;
  }
  h4 {
    font-size: 16px !important;
  }
  h5 {
    font-size: 13.28px !important;
  }
  h6 {
    font-size: 10.72px !important;
  }
  .btn {
    font-size: 14px;
  }
}

::-webkit-scrollbar-track {
  margin-top: 5px;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgb(229, 228, 228);
}
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: rgb(179, 177, 177);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(152, 150, 150);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-white {
  color: #fafafa !important;
}

a {
  color: rgb(33, 37, 41);
  text-decoration: unset;
  &:hover {
    color: black;
  }
}

.title {
  font-weight: 600;
}

.l0-font-size {
  font-size: 10px !important;
}

.l1-font-size {
  font-size: 12px !important;
}

.l2-font-size {
  font-size: 14px !important;
}

.l3-font-size {
  font-size: 16px !important;
}

.l4-font-size {
  font-size: 18px !important;
}
[placeholder] {
  text-overflow: ellipsis;
}

// .p-datatable-table {
//   word-break: break-all;
// }

.fw-600 {
  font-weight: 600;
}

.line-height-1 {
  line-height: 1;
}

// .p-dialog-content {
//   overflow-y: visible;
//   form {
//     max-height: 65vh;
//     overflow-y: auto;
//   }
// }
