.ErrorHandler-container {
    .text-grey {
        color: #fafafa;
    }
    .Errorhandler-wrapper {
        background-color: #3d4957;
        width: 40%;
        span {
            font-size: 7rem;
        }
    }
}
