

.dictionary-container {
    .p-datatable .p-datatable-thead > tr > th {
        background-color: #84bc3f;
    }

    .p-column-title {
        color: white;
    }

    .search-input {
        background-color: rgba(0, 0, 0, 0.03);
        width: 100%;
        color: #3c4852;
    }
    
    .search-input:focus {
        outline: none;
    }
    
    .btn-custom {
        color: rgb(109, 108, 108);
        font-size: 1.4rem;
    }
    
    .search-btn {
        background-color: rgba(0, 0, 0, 0.03);
        width: 8%;
    }
    
    .bg-light-green {
        background-color: #84bc3f;
    }
}
