.user-notification {
    .p-dialog-content {
        border-radius: 5px;
        padding: 15px;
    }
    .notification-img {
        width: 92%;
    }

    .item {
        max-height: 290px;
        overflow: auto;
    }

    .custom-carousel {
        .p-carousel-indicator button {
            height: 8px;
            width: 8px;
            border-radius: 4px;
        }
    }

    .close-btn {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .title {
        word-wrap: break-word;
    }

    .desc {
        text-align: left;
        p {
            margin-bottom: 0;
        }
    }

    .fix-width-250px {
        width: 250px;
    }

    .max-width-750px {
        min-width: 250px;
        max-width: 750px;
    }
}
