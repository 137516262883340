.process-container {
    // font-weight: 600;
    // border: 2px solid #222539 ;
    border-radius: 20px;
    background-color: #fff;

    color: #222539;
    .process-heading {
        border-bottom: 1.5px solid #9fa6b2;
    }
 
    .info-container {
        .horizontal-line {
            // width: ;
            height: 5px;
            background-color: #63a743;
        }

        .step-container.step-completed {
            position: relative;

            .step-btn {
                background-color: #63a743;
                width: 2.5vw;
                height: 2.5vw;
                border: none;
                z-index: 12;
                color: white;
            }
            .collapse-item {
                background-color: #63a743;
                position: absolute;
                top: 70px;
                width: 300px;
                border: 1.5px solid #63a743;
                color: white;
                font-weight: 400;
                border-radius: 5px;
            }
            .count-container {
                z-index: 12;
            }
        }

        .step-container {
            position: relative;
            flex: 1 1 0px;
            .step-btn {
                background-color: #9e9e9e;
                width: 2.5vw;
                height: 2.5vw;
                border: none;
                z-index: 12;
                color: white;
            }
            .collapse-item {
                background-color: #9e9e9e;
                position: absolute;
                top: 70px;
                width: 300px;
                border: 1.5px solid #9e9e9e;
                color: white;
                font-weight: 400;
                border-radius: 5px;
            }
            .count-container {
                z-index: 12;
            }
        }

        .step-container:not(:last-child)::before {
            content: "";
            position: absolute;
            height: 3px;
            display: flex;
            align-items: center;
            // width: 100%;
            background-color: #e0e0e0;
            left: 0;
            // transform: translateX(-0%);
            bottom: 30%;
            z-index: 10;
            width: 100%;
             
        }

        .step-container.completed:not(:last-child)::after {
            content: "";
            position: absolute;
            height: 3px;
            display: flex;
            align-items: center;
            // width: 100%;
            background-color: #63a743;
            left: 0;
            // transform: translateX(-0%);
            bottom: 30%;
            z-index: 11;
            animation: progress-animation 0.7s linear forwards;
        }
        @for $i from 0 to 10 {
            .step-container.completed:nth-child(#{$i + 1}n)::after {
                animation-delay: #{$i * 0.7}s;
            }
        }
    }
}

@keyframes progress-animation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
