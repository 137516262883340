.ca-loader-container {
    background-color:white;
    z-index: 1201;
    position: fixed;
    top:0;
    bottom: 0;
    right:0;
    left:0;
   .icons-wrapper {
    width: 170px;
    height: 120px;
    position: relative;
    color:#8373ec;
    .mdi-cog {
        display:flex;
        align-items:center;
        justify-content: center;
        font-size: 3rem;
        position: absolute;
        animation: cog-rotation 2.2s linear  infinite;
    }
    .mdi-cog:first-child {
        right: 50px;
        top: 0px;
        font-size: 2.5rem;
    }
    .mdi-cog:nth-child(2) {
          left: 34px;
          top: 20px;
    }
    .mdi-cog:nth-child(3) {
        right: 35px;
        bottom: -2px;
        font-size: 3.5rem;
    }

   }

   .lower-div {
    height: 4px;
    width:50px;
    position: absolute;
    bottom:18px ;
    left: -70px;
    animation:lower-div-animation 2.5s linear 1s infinite ;
    
   }

    
}

@keyframes cog-rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    
} 
