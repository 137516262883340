.help-container {
    // background-color: #434C53;
    color: #6d6c6e;
    p {
        pointer-events: none;
        color: #6d6c6e;
    }

    img {
        filter: brightness(75%);
    }

    a {
        background-color: #6d6c6e;
        transition: all 0.2s ease;
        border-radius: 0;
    }

    a:hover {
        border-radius: 20px;
    }

    .content-div {
        width: 80%;
    }


}