@import "../../../style.mixins.scss";

$theme-color: #293d56;
$theme-bg-color: #eef1f5;
.bi-home {
    width: 82%;
    margin-left: 18%;
    max-height: 100vh;
    @media screen and (min-width: 1300px) {
        margin-left: 250px;
        width: calc(100% - 250px);
    }
}



.bi-home-container {
    background-color: $theme-bg-color;
    height: calc(100% - 56px);
    .desc {
        color: #969798;
    }
    // min-height: calc(100vh - 48px);
    .main-container-wrapper {
        width: 92%;
        // max-width: 959px;
        @media screen and (min-width: 1300px) {
            // max-width: 1536px;
        }

        .home-content {
            background-color: white;
            height: 83%;

            .home-image{
                height: 100%;
            }

            .text-container {
                width: 55%;
                text-align: start;
            }
        }

        .bi-content-container {
            .bi-app {
                // background-color:#464c72 ; theme -1
                // background-color: #20204c;  theme -2
                background-color: white;
                // width: 37%;
                height: 60px;
                @media screen and (min-width: 1400px) {
                    height: 80px;
                }
                overflow: hidden;
                // border-radius: 7px;
                .fact-intro {
                    width: 20%;
                }
            }
        }

        .p-carousel-indicators {
            padding: 0;
            .p-carousel-indicator {
                button {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
                display: none;
            }
        }

        .carousel-top-container {
            .mail-btn {
                background-color: $theme-color;
                color: white;
                // border-radius: 20px;
                display: inline-block;
                transition: all 0.3s ease;

                .mdi {
                    line-height: 0px;
                    font-size: 1.2rem;
                }
            }

            .desc {
                color: #969798;
            }

            .mail-btn:hover {
                border-radius: 20px;
            }
        }
    }
}

.W-80 {
    width: 80% !important;
    max-width: 80% !important;
}

