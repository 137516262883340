@import "./style.mixins.scss";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-primary {
  background-color: #3d4957;
  border-color: #3d4957;
  &:focus {
    background-color: rgb(75, 89, 107);
    border-color: rgb(82, 98, 117);
  }

  &:hover {
    background-color: rgb(51, 60, 74);
    border-color: rgb(82, 98, 117);
  }
}

.text-global-custom {
  color: #3d4957;
}

.font-14 {
  font-size: 14px;
}

.bg-light-blue {
  background-color: #eff6ff;
}

.preview-icon {
  font-size: 22px;
  background: rgba(0, 0, 0, 0.04);
  height: 40px;
  width: 40px;

  padding: 0 10px;
  margin: 5px;
}

.home-height {
  height: calc(100vh - 87px);
}
.main-container {
  min-height: calc(100vh - 70px);
}
body {
  position: relative;
}

.max-col-25 {
  td,
  th {
    max-width: 200px;
    word-break: break-word;
    text-overflow: ellipsis;
    text-align: left;
  }
}

td,
th {
  text-align: left;
}

.margin-left-side {
  margin-left: $side-nav-width;
}

.shadow-box {
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px 0 !important;
}

.card {
  border-color: #bec5cb;
}

.margin-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.fs-small {
  font-size: 10px;
}

.p-toast-detail {
  text-align: left;
}

@media screen and (min-width: 1300px) {
  .global-small-text {
    font-size: 16px !important;
  }
}

.text-justify {
  text-align: start;
}

.font-Montserrat {
  font-family: "Montserrat";
}

.margin-bottom-1_1 {
  margin-bottom: 1.1rem;
}
