@import "../../../style.mixins.scss";

.quiz-parent {
    .quiz-container {
        .carousel-control-next,
        .carousel-control-prev,
        .submit-btn {
            color: rgb(34, 37, 57);
            color: white;
            // opacity: 1;
            background-color: #5c91e1;
            border: 1px solid #9cc2f0;
            padding: 0.25rem 0.5rem;
        }
        //  .carousel-control-prev {
        //      width: auto;
        //      display: block;
        //      bottom: -35px;
        //      top: unset;
        //      left: 40%;
        //      right: unset;

        //  }
        //  .carousel-control-next {
        //        width: auto;
        //        display: block;
        //        top: unset;
        //        bottom: -35px;
        //        right: 40%;
        //        left:unset ;

        //  }
        .field {
            height: 100%;
            color: rgb(34, 37, 57);
            background-color: #eff6ff;

            .question {
                background-color: white;
                padding: 0.7rem;
                border-radius: 10px;
                cursor: pointer;
                .p-checkbox-label {
                    cursor: pointer;
                }
            }
            // .question.selected {
            //     background-color: #5c91e1;
            //     color: white;
            // }
        }
    }
    .result-container {
        color: #6c5dd4;
        .stars-container {
            background-color: #78d64b1f;
            .star-icon {
                font-size: 3rem;
            }
            .scored {
                color: #78d64b;
            }
            .unscored {
                color: #78d64b1f;
            }
        }
        .score-container {
            background-color: #f2f0fe;
        }
    }
}
