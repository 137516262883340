.manage-de {
    table {
        table-layout: fixed;
        word-wrap: break-word;
       
    }

    .last-border-none:last-of-type {
        td{
            border-bottom: none;
        }
    }
    .dialog-heading {
        color: rgb(99, 98, 98);
    }

    .width-10{
        width: 10%;
    }
        
}


.warning-dialog {
   
         .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:first-child  {
            width: 7% !important;
         }
        
        .p-datatable-tbody > tr > td {
            overflow: hidden; 
            white-space:nowrap;
            text-overflow:ellipsis; 
        }
    
        .company-table {
            .p-datatable-responsive-stack .p-datatable-table .p-datatable-tbody > tr > td {
             overflow: hidden; 
             white-space:nowrap;
             text-overflow:ellipsis;        
            }

            .p-datatable-responsive-stack .p-datatable-table .p-datatable-tbody > tr > td  {
                width: 60%;
             }
         }
}

.ManageDeForm {
              
            .p-multiselect {
                display: flex;
                max-width: 100%;
            
                .p-multiselect-trigger{
                   display: block;
                   width: auto;
                }
            }
            .p-dropdown {
                display: flex;
                max-width: 100%;
            
                .p-multiselect-trigger{
                   display: block;
                   width: auto;
                }
            }
            
}
