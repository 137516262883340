@import  '../../../style.mixins.scss';
.table-container {
    font-family: "Open Sans";
    .p-column-title {
        color: #3d4957
    }

    .p-paginator .p-dropdown {
        padding: 0.2rem 1rem;
        height: auto;

    }

    .p-dropdown-label {
        margin-right: 0.5rem;
    }
    .p-datatable {
        font-family: "Open Sans";
    }
    .p-datatable-thead , .p-datatable-tbody  {
        font-size: $l2-font-size;
    }
    .p-paginator {
        font-family: "Open Sans";
    }

    .table-header {
        .search-input {
            border: 0.5px solid #3d4957;

            input {
                color: #3d4957;
                font-weight: 600;
                height: 100%;
            }
            span {
                font-weight: 600;
                color: #3d4957
            }

            input:focus {
                outline: none;
            }
        }
       
    }
    .p-column-filter-menu{
        margin-left: 0.5rem;
    }

    .width-100 {
        width: 100%;
    }
     
   
}

.p-column-filter-overlay {

    .p-column-filter-add-rule,
    .p-column-filter-operator,
    .p-column-filter-buttonbar,
    .p-column-filter-constraint {
        padding: 0.5rem 1rem;
    }

    .p-button {
        padding: 0.3rem 0.6rem;
    }

    .p-column-filter {
        padding: 0.25rem 0.75rem;
    }

    .p-inputtext {
        font-size: $l2-font-size;
        font-weight: 600;
    }

    .p-dropdown {
        padding: 0.25rem 0.75rem;
    }
}
